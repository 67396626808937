<template>
    <div class="custom-content">
        <el-scrollbar class="custom-wrap">
            <el-table class="customTable" :data="customList" style="width: 100%; flex: 1;" height="1%" size="medium"
                      :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                      :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                <el-table-column prop="content" label="基本信息" align="left">
                    <template slot-scope="scope">
                        <div class="item-content">
                            <div class="item-left">
                                <img :src="scope.row.good_img" alt="">
                            </div>
                            <div class="item-right">
                                <div class="item-title">{{scope.row.good_name}}</div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="kehu_num" label="客户消息数" align="center" width="200"></el-table-column>
                <el-table-column prop="kefu_num" label="客服消息数" align="center"></el-table-column>
                <el-table-column prop="goods_url" label="是否下单" align="center">
                    <template slot-scope="scope">
                        <span>已下单</span>
                    </template>
                </el-table-column>
                <el-table-column prop="fileList" label="操作" align="center" width="200">
                    <template slot-scope="scope">
                        <div class="link-box">
                            <el-link type="success" :underline="false" @click="viewRow(scope.row)">
                                聊天记录
                            </el-link>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <!--            <pagination class="pages-center" :total="total" @current-change="handleCurrentChange"/>-->
        </el-scrollbar>
        <el-dialog title="聊天记录" :visible.sync="dialogVisible" width="60%" :before-close="handleClose"
                   custom-class="green-dialog goods-img-dialog">
            <div class="record-content">
                <div class="record-item" v-for="(item,index) in record" :key="index">
                    <div class="item-question">
                        <div class="item-title question">客户问题</div>
                        <div class="item-content">{{item.questions}}</div>
                    </div>
                    <div class="item-answer">
                        <div class="item-title answer">客服回答</div>
                        <div class="item-content">{{item.answer}}</div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import pagination from "@/components/pagination";
    import {drill_data_map, exam_data_map} from "@/utils/apis"

    export default {
        name: "qualificationQuery",
        components: {
            pagination
        },
        data() {
            return {
                customList: [],
                total: 0,
                currentPageNum: 1,
                opId: Number(this.$route.query.id) || null,
                courseId: Number(this.$route.query.course_id) || null,
                platform: this.$route.meta.type,
                record: [],
                dialogVisible: false,
            }
        },
        created() {
            this.getGoodsList()
        },
        methods: {
            getGoodsList() {
                if (this.platform == 0) {
                    let params = {
                        op_id: this.opId,
                        module: 2
                    }
                    drill_data_map(params).then(res => {
                        if (res.data) {
                            this.customList = res.data
                            this.total = res.data.total
                        }
                    }).catch(err => {
                        console.error(err)
                    })
                } else {
                    let params = {
                        module: 2
                    }
                    exam_data_map(params).then(res => {
                        if (res.data) {
                            this.customList = res.data
                            this.total = res.data.total
                        }
                    }).catch(err => {
                        console.error(err)
                    })
                }
            },
            handleCurrentChange(val) {
                this.currentPageNum = val;
                this.getGoodsList();
            },
            viewRow(row) {
                this.record = []
                this.record = row.questions
                this.dialogVisible = true
            },
            handleClose() {
                this.dialogVisible = false
            }
        }
    }
</script>

<style scoped lang="scss">
    .custom-content {
        margin-top: 18px;
        height: calc(100% - 70px);
        display: flex;
        flex-direction: column;
        padding-bottom: 40px;

        .create-btn {
            width: 118px;
            height: 40px;
            background: #FFFFFF;
            border: 1px solid #2DC079;
            border-radius: 4px;
            color: #2DC079;
            line-height: 40px;
            cursor: pointer;
            text-align: center;
            font-size: 16px;
        }

        .custom-wrap {
            flex: 1;
            height: 1%;
            display: flex;
            flex-direction: column;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
                height: 100%;

                .el-scrollbar__view {
                    margin: 0 20px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                }
            }

            .customTable {
                margin-top: 12px;
            }

            .item-content {
                display: flex;
                align-items: center;

                .item-left {
                    width: 80px;
                    height: 80px;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                .item-right {
                    margin-left: 20px;

                    .item-title {
                        width: 200px;
                        color: #333333;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
            }

            .pages-center {
                margin-bottom: 0;
            }
        }

        .record-content {
            margin-top: 30px;

            &:first-child {
                margin-top: 0;
            }

            .item-title {
                font-size: 16px;

                &.question {
                    color: #2DC079;
                }

                &.answer {
                    color: #2461EF;
                    margin-top: 30px;
                }
            }

            .item-content {
                margin-top: 16px;
                font-size: 16px;
            }
        }
    }
</style>